.ReactModal__Overlay {
  z-index: 9;
}

.st-modal {
  background: white;
  position: absolute;
  width: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -200%);
  box-shadow: -1px 4px 10px 3px rgba(0, 0, 0, 0.1);
  // opacity: 0;
  transition: transform 150ms;
  display: flex;
  flex-direction: column;
  max-height: 90%;

  @media screen and (min-width: 768px) {
    width: 75%;
  }

  @media screen and (min-width: 992px) {
    width: 60%;
    max-width: 660px;
  }
}

.st-modal--after-open {
  // opacity: 100;
  transform: translate(-50%, -50%);
}

.st-modal--before-close {
  // opacity: 0;
  transform: translate(-50%, -200%);
}
