.st-sidebox {
  overflow: scroll;
  max-width: 400px;
  width: 90%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(400px);
  background: white;
  transition: transform 150ms;
  box-shadow: -1px 4px 10px 3px rgba(0, 0, 0, 0.1);
}

.st-sidebox--after-open {
  transform: translateX(0);
}

.st-sidebox--before-close {
  transform: translateX(400px);
}
