* {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

html {
  box-sizing: border-box;
  font-size: 0.75em;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: #ededed;
}

.filter-1 {
  box-shadow: -1px 4px 10px 3px rgba(0, 0, 0, 0.1);
}

.sf-hd {
  -webkit-transform: translate3d(0, 0, 0);
}
